.input-transparent {
    background-color: rgba(0, 0, 0, 0) !important;
    /* color: black; */
    border: none !important;
    outline: none !important;
    margin: 0 !important;
    height: inherit !important;
}
  
.input-transparent:focus {
    box-shadow: inherit !important;
}

.main-loader {
    position: fixed;
    z-index: 1040;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    // background-color: #fff;
    // background: rgba(255, 255, 255, 0.333);
    // backdrop-filter: blur(10px);
}

.table-wrap td, .table-wrap th {
    vertical-align: middle !important;
}

.table-wrap th.nowrap, .table-wrap td.nowrap {
    padding: 5px 10px;
    white-space: nowrap;
    vertical-align: top;
    border-bottom-width: 0px !important;
    /* border-left: 0px solid #a4b7c1 !important;
    border-right: 1px solid #a4b7c1 !important; */
}

/* .table-record-fix-width th, .table-record-fix-width td {
    padding: 5px 10px;
    white-space: pre-wrap;
    vertical-align: top;
    border-bottom-width: 0px !important;
    border-left: 0px solid #a4b7c1 !important;
    border-right: 1px solid #a4b7c1 !important;
    max-width: 400px;
    max-width: 200px;
} */



/* Record */
.table-info-summary-record th {
    background-color: var(--info);
}

.count-summary-record {
    position: absolute;
    top: -.25rem;
    right: -10px;
    background: #fff;
    height: 2.5rem;
    width: 4rem;
}

#modal-profile {
    .profile-img {
        border: 0.357rem solid #fff;
        background-color: #fff;
        border-radius: 0.428rem;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
        width: 20%;
    }
}

/* datalist autocomplete */
.autocomplete-results {
  position: absolute;
  padding-inline-start: 0;
  padding: 0;
  margin-top: 0.5rem;
  z-index: 99;
  width: max-content;
  max-height: 20em;
  overflow-y: auto;
  font-size: 13px;
  list-style: none;
  background: #fff;
  outline: 1px solid #e4e7ea;
  outline-offset: -1px;
  border-radius: 6px;
}
  
.c-dark-theme .autocomplete-results {
  background: #3d3e47;
  outline-color: rgba(255, 255, 255, 0.09);
}

.autocomplete-item {
  display: block;
  width: 100%;
  padding: 0.5rem;
  overflow: hidden;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: inherit;
  border: 0;
}

.autocomplete-item:hover {
  background-color: #7367f0;
  color: #fff;
}

.autocomplete-item:hover > * {
  color: #fff;
}

.autocomplete-item:hover .text-muted {
  color: #ffffffd1 !important;
}

/* end autocomplete */